export const ROUTES = {
  HOME: '/',
  ABOUT: '/us',
  NFT: '/nft',
  TOKEN: '/token',
  METAVERSE:'/metaverse',
  COMINGSOON:'/comingsoon',
};

export const ROUTE_NAMES = {
  HOME: 'Home',
  ABOUT: 'About Us',
  SERVICES: 'FAQ',
  NFT: 'Nft',
  TOKEN: 'Token',
  METAVERSE: 'Metaverse',
  COMINGSOON: 'Coming Soon',
};
