import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import { ROUTES } from '../constants/routes';

import Loading from '../components/Loading/Loading';

const HomePage = lazy(() => import('../pages/HomePage'));
const AboutUsPage = lazy(() => import('../pages/AboutUsPage'));
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));
const RoadMapPage = lazy(() => import('../pages/Roadmap'));
const DisclaimerPage = lazy(() => import('../pages/DisclaimerPage'));
const ComingSoon = lazy(() => import('../components/ComingSoon/ComingSoon'));
const router = createBrowserRouter([
  {
    path: ROUTES.HOME,
    element: <HomePage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: ROUTES.ABOUT,
    element: <AboutUsPage />,
  },
  {
    path: ROUTES.NFT,
    element: <DisclaimerPage />,
  },
  {
    path: ROUTES.TOKEN,
    element: <RoadMapPage />,
  },
  {
    path: ROUTES.METAVERSE,
    element: <NotFoundPage />,
  },
  {
    path: ROUTES.COMINGSOON,
    element: <ComingSoon />,
  },
]);

export const AppRouter = () => (
  <Suspense
    fallback={
      <div>
        <Loading />
      </div>
    }
  >
    {' '}
    <RouterProvider router={router} />
  </Suspense>
);
